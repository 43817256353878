.country-list {
    display: flex;
    flex-direction: column;
    padding: 5px;
  }

.country-reside {
    padding-bottom: 10px;
}

.fixed-width-dropdown {
  width: 150px; 
}