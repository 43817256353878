@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
  
    body {
      font-family: 'Open Sans', sans-serif;
    }
  }